import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ArchiveHero from 'src/components/organisms/ArchiveHero/ArchiveHero';
import CoreLayout from 'src/layouts/CoreLayout/CoreLayout';
import { sanitizeEncodedChar } from 'src/utils';
import { IMenuOpen, IPageContext } from 'src/utils/interfaces';
import styles from './PostArchiveTemplate.module.scss';

export class UnconnectedPostArchiveTemplate extends PureComponent<
  IPageContext
> {
  render() {
    const { pageContext, location } = this.props;
    const pageTitle = sanitizeEncodedChar(pageContext.title);

    return (
      <>
        <CoreLayout
          location={location}
          title={pageTitle}
          description={
            pageContext.yoast_meta &&
            pageContext.yoast_meta.yoast_wpseo_metadesc
          }
          data-test="template-archive"
          className={classNames([
            styles.archive,
            {
              [`archive__${pageContext.slug &&
                pageContext.slug.replace('_', '-')}`]: pageContext.slug,
            },
          ])}
        >
          <ArchiveHero pageContext={pageContext} location={location} />
        </CoreLayout>
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ core: { isMenuOpen } }: IMenuOpen) => ({
  isMenuOpen,
});

export default connect(
  mapStateToProps,
  null
)(UnconnectedPostArchiveTemplate);
